
let cities = {
    'Western': {
        'colombo': [
            "Colombo 01 - Fort",
            "Colombo 02 - Slave Island &amp; Union Place",
            "Colombo 03 - Kollupitiya",
            "Colombo 04 - Bambalapitiya",
            "Colombo 05 - Havelock Town &amp; Kirulapona",
            "Colombo 06 - Wellawatte &amp; Pamankada",
            "Colombo 07 - Cinnamon Gardens",
            "Colombo 08 - Borella",
            "Colombo 09 - Dematagoda",
            "Colombo 10 - Maradana",
            "Colombo 11 - Pettah",
            "Colombo 12 - Hultsdorf",
            "Colombo 13 - Kotahena &amp; Kochchikade",
            "Colombo 14 - Grandpass",
            "Colombo 15 - Modera &amp; Mattakkuliya"
        ],
        'Colombo Greater': [
            "Aggona",
            "Angoda",
            "Angulana",
            "Athurugriya",
            "Attidiya",
            "Avissawella",
            "Battaramulla",
            "Beddagana",
            "Bellanvila",
            "Bokundara",
            "Bope",
            "Bopitiya",
            "Boralesgamuwa",
            "Dedigamuwa",
            "Dehiwala",
            "Delkanda",
            "Egoda Uyana",
            "Embuldeniya",
            "Gangodawila",
            "Godagama",
            "Gothatuwa",
            "Habarakada",
            "Hanwella",
            "Himbutana",
            "Hiripitya",
            "Hokandara",
            "Homagama",
            "Jambugasmulla",
            "Kaduwela",
            "Kahathuduwa",
            "Kalubowila",
            "Katubedda",
            "Kawdana",
            "Kesbewa",
            "Kiriwattuduwa",
            "Kohuwala",
            "Kolonnawa",
            "Kosgama",
            "Koswatta",
            "Kotikawatta",
            "Kottawa",
            "Kotte",
            "Lunawa",
            "Madapatha",
            "Madiwela",
            "Maharagama",
            "Malabe",
            "Mattegoda",
            "Meegoda",
            "Mirihana",
            "Moratuwa",
            "Mount Lavinia",
            "Mullegama",
            "Mulleriyawa",
            "Mulleriyawa New Town",
            "Napawela",
            "Nawala",
            "Nawinna",
            "Nedimala",
            "Nugegoda",
            "Obesekarapura",
            "Orugodawatta",
            "Padukka",
            "Pagoda",
            "Paliyagoda",
            "Pannipitiya",
            "Pelawatta",
            "Pepiliyana",
            "Piliyandala",
            "Pitipana Homagama",
            "Polgasowita",
            "Rajagiriya",
            "Rathmalana",
            "Ratmalana",
            "Rattanapitiya",
            "Sapugaskande",
            "Sedawatte",
            "Siddamulla",
            "Sri Jayawardenapura Kotte",
            "Talangama",
            "Talawatugoda",
            "Thalawathugoda",
            "Udahamulla",
            "Waga",
            "Watareka",
            "Welikada",
            "Welivita",
            "Wellampitiya",
            "Werahera",
            "Wijerama"
        ],
        'Gampaha':
            [
                "Alawala",
                "Ambagaspitiya",
                "Ambepussa",
                "Amuhena",
                "Andiambalama",
                "Attanagalla",
                "Badalgama",
                "Bemmulla",
                "Biyagama",
                "Buthpitiya",
                "Danowita",
                "Debahera",
                "Dekatana",
                "Delgoda",
                "Demalagama",
                "Dewalapola",
                "Divulapitiya",
                "Divuldeniya",
                "Dompe",
                "Dunagaha",
                "Ekala",
                "Ellakkala",
                "Essella",
                "Gampaha Town",
                "Ganemulla",
                "Gonawala",
                "Heiyanthuduwa",
                "Hendala",
                "Henegama",
                "Ihala Madampella",
                "Ja-Ela",
                "Kadawatha",
                "Kalagedihena",
                "Kaleliya",
                "Kaluaggala",
                "Kandana",
                "Katana",
                "Katunayake",
                "Kelaniya",
                "Kimbulapitiya",
                "Kiribathgoda",
                "Kirindiwela",
                "Kitulwala",
                "Kotadeniyawa",
                "Kotugada",
                "Lunugama",
                "Mabodala",
                "Mabole",
                "Madelgamuwa",
                "Mahabage",
                "Makola",
                "Malwana",
                "Mawaramandiya",
                "Mellawagedara",
                "Minuwangoda",
                "Mirigama",
                "Mudungoda",
                "Naiwella",
                "Negombo",
                "Nikahetikanda",
                "Nittambuwa",
                "Pallewela",
                "Pamunugama",
                "Pamunuwatta",
                "Pasyala",
                "Peliyagoda",
                "Pugoda",
                "Radawadunna",
                "Raddolugama",
                "Ragama",
                "Ranala",
                "Rukmale",
                "Seeduwa",
                "Siyambalape",
                "Udugampola",
                "Urapola",
                "Uswetakeiyawa",
                "Vayangoda",
                "Veyangoda",
                "Walpita",
                "Walpola",
                "Wanaluwewa",
                "Wattala",
                "Weliweriya",
                "Weweldeniya",
                "Yakkala",
                "Yatiyana"
            ],
            'Kalutara' : [
                "Agalawatta",
                "Alubomulla",
                "Aluthgama",
                "Arukgoda",
                "Athwelthota",
                "Aubomulla",
                "Awiththawa",
                "Baduraliya",
                "Bandaragama",
                "Bekkegama",
                "Bellana",
                "Beruwala",
                "Bolossagama",
                "Bombuwala",
                "Boralugoda",
                "Bulathsinhala",
                "China Fort",
                "Daluwatta",
                "Dediyawala",
                "Delduwa",
                "Dharga Town",
                "Dibbedda",
                "Diggala",
                "Diyalagoda",
                "Dodamgoda",
                "Dodangoda",
                "Dombagoda",
                "Egal Oya",
                "Eluwila",
                "Galpatha",
                "Galthude",
                "Gamagoda",
                "Gonapola Junction",
                "Gorakana",
                "Goraka Pola",
                "Govinna",
                "Halkandawila",
                "Haltota",
                "Halwatura",
                "Handapangoda",
                "Hedigalla Colony",
                "Hirana",
                "Horana",
                "Horawala",
                "Horethuduwa",
                "Ingiriya",
                "Ittapana",
                "Kalamulla",
                "Kaludewala",
                "Kalugala",
                "Kalutara",
                "Kalutara Town",
                "Kaluwamodara",
                "Kananwila",
                "Katukurunda",
                "Kehelwatta",
                "Keselwatta",
                "Kethhena",
                "Kiriberiya",
                "Kitulgoda",
                "Koholana",
                "Kuruppu Mulla",
                "Maggona",
                "Mahagama",
                "Mahakalupahana",
                "Malamulla",
                "Malegoda",
                "Maradana",
                "Mathugama",
                "Matugama",
                "Meegahatenna",
                "Meegama",
                "Melegama",
                "Millaniya",
                "Millewa",
                "Miwanapalana",
                "Molkawa",
                "Molligoda",
                "Moragalla",
                "Morontuduwa",
                "Mun Hena",
                "Nagas Handiya",
                "Narthupana",
                "Neboda",
                "Omaththa",
                "Owitigala",
                "Paiyagala",
                "Panadura",
                "Pannila",
                "Paragastota",
                "Paragoda",
                "Paraigama",
                "Paraththa",
                "Pibura",
                "Pohaddaramulla",
                "Pokunuwita",
                "Polgampola",
                "Poruwedanda",
                "Pothuwila",
                "Remunagoda",
                "Serupita",
                "Tebuwana",
                "Thalpitiya",
                "Thanthirimulla",
                "Thebuwana",
                "Thudugala",
                "Uduwara",
                "Wadduwa",
                "Walallawita",
                "Walana",
                "Walathara",
                "Waskaduwa",
                "Wehada",
                "Welipenna",
                "Welmilla Junction",
                "Weththewa",
                "Wettumakade",
                "Yagirala",
                "Yatadolawatta",
                "Yatawara Junction"
            ]
    },
    'Central':{
        'Kandy':[
            "Akurana",
            "Aladeniya",
            "Alawatugoda",
            "Aludeniya",
            "Ambatenna",
            "Ampitiya",
            "Ankubura",
            "Ankumbura",
            "Aruppola",
            "Atabage",
            "Balana",
            "Batugoda",
            "Bawlana",
            "Bopana",
            "Danthure",
            "Daulagala",
            "Dedunupitiya",
            "Deltota",
            "Digana",
            "Doluwa",
            "Doragamuwa",
            "Etulgama",
            "Galaboda",
            "Galagedara",
            "Galaha",
            "Galhinna",
            "Gampola",
            "Gelioya",
            "Godamunna",
            "Gonagantenna",
            "Guhagoda",
            "Gunnepana",
            "Gurudeniya",
            "Haguranketha",
            "Halloluwa",
            "Handaganawa",
            "Handessa",
            "Hanguranketha",
            "Haragama",
            "Harankahawa",
            "Harispattuwa",
            "Hasalaka",
            "Hatharaliyadda",
            "Hewaheta",
            "Hindagala",
            "Hondiyadeniya",
            "Hunnasgiriya",
            "Jambugahapitiya",
            "Kadugannawa",
            "Kahataliyadda",
            "Kalugala",
            "Kandy Town",
            "Kapuliyadde",
            "Karandagolla",
            "Katugastota",
            "Kengalla",
            "Ketakumbura",
            "Kiribathkumbura",
            "Kolongoda",
            "Kulugammana",
            "Kumbukkandura",
            "Kundasale",
            "Leemagahakotuwa",
            "Lewella",
            "Lunuketiya Maditta",
            "Madawala",
            "Madugalla",
            "Madulkele",
            "Mahadoraliyadda",
            "Mahaiyawa",
            "Mahamedagama",
            "Mailapitiya",
            "Makuldeniya",
            "Mandaram Nuwara",
            "Marassana",
            "Maturata",
            "Mawatura",
            "Mawilmada",
            "Medamahanuwara",
            "Medawala Harispattuwa",
            "Menikdiwela",
            "Menikhinne",
            "Mimure",
            "Minigamuwa",
            "Minipe",
            "Muruthalawa",
            "Naranpanawa",
            "Nattarampotha",
            "Nawalapitiya",
            "Nugaliyadda",
            "Nugawela",
            "Pallekele",
            "Pallekotuwa",
            "Panwilatenna",
            "Paradeka",
            "Pasbage",
            "Pattitalawa",
            "Peradeniya",
            "Pilawala",
            "Pilimthalawa",
            "Poholiyadda",
            "Polgolla",
            "Poththapitiya",
            "Pujapitiya",
            "Pupuressa",
            "Pussellawa",
            "Rajawella",
            "Rambukpitiya",
            "Rangala",
            "Rantembe",
            "Rathukohodigala",
            "Rikillagaskada",
            "Sangarajapura",
            "Senarathwela",
            "Talatuoya",
            "Teldeniya",
            "Thalatuoya",
            "Thawalanthenna",
            "Thennekubura",
            "Udahentenna",
            "Udahingulwala",
            "Uda Peradeniya",
            "Udawatta",
            "Ududumbara",
            "Uduwa",
            "Uduwahinna",
            "Uduwela",
            "Ulapane",
            "Ulpothagama",
            "Unuwinna",
            "Velamboda",
            "Wattappola",
            "Wattegama",
            "Weligalla",
            "Weligampola",
            "Wendaruwa",
            "Werellagama",
            "Wettawa",
            "Wilanagama",
            "Yahalatenna",
            "Yatihalagala"
          ],
        'Matale':[
            "Akuramboda",
            "Alawatta",
            "Ambana",
            "Ataragallewa",
            "Bambaragaswewa",
            "Beligamuwa",
            "Dambulla",
            "Dankanda",
            "Devagiriya",
            "Dewahuwa",
            "Dullewa",
            "Dunkolawatta",
            "Dunuwilapitiya",
            "Elkaduwa",
            "Erawula Junction",
            "Etanawala",
            "Galewela",
            "Gammaduwa",
            "Gangala Puwakpitiya",
            "Handungamuwa",
            "Hattota Amuna",
            "Hettipola",
            "Illukkumbura",
            "Imbulgolla",
            "Inamaluwa",
            "Kaikawala",
            "Kalundawa",
            "Kandalama",
            "Karagahinna",
            "Katudeniya",
            "Kavudupelella",
            "Kibissa",
            "Kiwula",
            "Kongahawela",
            "Laggala Pallegama",
            "Leliambe",
            "Lenadora",
            "Madawala Ulpotha",
            "Madipola",
            "Mahawela",
            "Mananwatta",
            "Maraka",
            "Matale",
            "Matale Town",
            "Melipitiya",
            "Metihakka",
            "Millawana",
            "Muwandeniya",
            "Nalanda",
            "Na ula",
            "Nugagolla",
            "Opalgala",
            "Ovilikanda",
            "Palapathwela",
            "Pallepola",
            "Perakanatta",
            "Pubbiliya",
            "Ranamuregama",
            "Rattota",
            "Selagama",
            "Sigiriya",
            "Talagoda Junction",
            "Talakiriyagama",
            "Udasgiriya",
            "Udatenna",
            "Ukuwela",
            "Wahacotte",
            "Walawela",
            "Wehigala",
            "Welangahawatte",
            "Wewalawewa",
            "Wilgamuwa",
            "Yatawatta"
          ],
        'Nuwara Eliya':[
            "Adhikarigama",
            "Agarapatana",
            "Ambagamuwa Udabulathgama",
            "Ambatalawa",
            "Ambewela",
            "Bambarakelle",
            "Barawardhanaoya",
            "Bogawantalawa",
            "Bopattalawa",
            "Dagampitiya",
            "Dayagama Bazaar",
            "Degampitiya",
            "Dikoya",
            "Doragala",
            "Dunukedeniya",
            "Ginigathena",
            "Gonakele",
            "Hakgala",
            "Halgran Oya",
            "Hangarapitiya",
            "Hapugastalawa",
            "Harangalagama",
            "Harasbedda",
            "Hatton",
            "Hawa Eliya",
            "Hedunuwewa",
            "Hitigegama",
            "Idamegama",
            "Kalaganwatta",
            "Kandapola",
            "Katukitula",
            "Keerthi Bandarapura",
            "Kelanigama",
            "Ketaboola",
            "Kotagala",
            "Kotmale",
            "Kottellena",
            "Kumbalgamuwa",
            "Kumbukwela",
            "Kurupanawela",
            "Labookelle",
            "Labukele",
            "Laxapana",
            "Lindula",
            "Madulla",
            "Magastota",
            "Maldeniya",
            "Maskeliya",
            "Maswela",
            "Meethalawa",
            "Mipanawa",
            "Mipilimana",
            "Morahenagama",
            "Munwatta",
            "Nanuoya",
            "Nawathispane",
            "Nildandahinna",
            "Norwood",
            "Nuwara Eliya",
            "Nuwara Eliya Town",
            "Padiyapelella",
            "Palena",
            "Patana",
            "Pitawala",
            "Pundaluoya",
            "Pussalamankada",
            "Radella",
            "Ragala",
            "Ramboda",
            "Rozella",
            "Rupaha",
            "Ruwaneliya",
            "Sadathenna",
            "Santhipura",
            "Talawakele",
            "Teripeha",
            "Udamadura",
            "Udapussallawa",
            "Walapane",
            "Watagoda",
            "Watagoda Hanspattuwa",
            "Watawala",
            "Widulipura",
            "Wijebahukanda"
          ],
    },
    'Eastern':{
        'Ampara':[
            "Addalaichenai",
            "Akkaraipattu",
            "Ampara",
            "Ampara Town",
            "Bakmitiyawa",
            "Dadayamtalawa",
            "Damana",
            "Deegawapiya",
            "Dehiattakandiya",
            "Digamadulla",
            "Dorakumbura",
            "Gonagolla",
            "Hingurana",
            "Hulannuge",
            "Irakkamama",
            "Kalmunai",
            "Karativu",
            "Koknahara",
            "Kolamanthalawa",
            "Komari",
            "Lahugala",
            "Mahaoya",
            "Malwatta",
            "Mangalagama",
            "Marathamune",
            "Mawanagama",
            "Moragahapallama",
            "Namaloya",
            "Navithanveli",
            "Nawamedagama",
            "Nintavur",
            "Oluvil",
            "Padiyathalawa",
            "Pahalalanda",
            "Palamunai",
            "Panama",
            "Pannalagama",
            "Periyaneelavanai",
            "Pottuvil",
            "Rajagalatenna",
            "Sainthamaruthu",
            "Sammanthurai",
            "Serankada",
            "Siripura",
            "Siyambalawewa",
            "Tempitiya",
            "Thambiluvil",
            "Tirukkovil",
            "Uhana"
          ],
        'Batticaloa':[
            "Batticaloa Town",
            "Chenkaladi",
            "Eravur",
            "Kalkudah",
            "Karadiyanaru",
            "Kattankudy",
            "Koddamunai",
            "Mankemi",
            "Puliyanthivu",
            "Puthur",
            "Vakarai"
          ],
        'Trincomalee':[
            "Agbopura",
            "Anna Nagar",
            "Arunagiri Nagar",
            "Buckmigama",
            "Chinabay",
            "Dehiwatte",
            "Deva Nagar",
            "Echchilampattai",
            "Galmetiyawa",
            "Gomarankadawala",
            "Kaddaiparichchan",
            "Kanniya",
            "Kantale",
            "Kavaddikudah",
            "Kiliveddy",
            "Kinniya",
            "Kuchchaveli",
            "Kumburupiddy",
            "Kurinchakemy",
            "Lankapatuna",
            "Linganagar",
            "Mahadivulwewa",
            "Maharugiramam",
            "Mallikativu",
            "Matikali",
            "Mawadichchenai",
            "Mihindapura",
            "Mullipothana",
            "Murugapuri",
            "Mutur",
            "Neelapola",
            "Nelsonpura",
            "Nilaveli",
            "Nithiyapuri",
            "Orrs Hill",
            "Palaiyoothu",
            "Pankulam",
            "Rottawewa",
            "Sampaltivu",
            "Sampur",
            "Samudragama",
            "Serunuwara",
            "Seruwila",
            "Sirajnagar",
            "Somapura",
            "Tampalakamam",
            "Thirukadaloor",
            "Tiriyayi",
            "Toppur",
            "Trincomalee Town",
            "Uppaveli",
            "Vellamanal",
            "Wanela"
          ]
    },
    'North Central':{
        'Anuradhapura':[
            "Andiyagala",
            "Anuradhapura Town",
            "Awukana",
            "Dematawewa",
            "Dunumadalawa",
            "Elayapattuwa",
            "Eppawala",
            "Etaweeragollewa",
            "Galenbindunuwewa",
            "Galkadawala",
            "Galkiriyagama",
            "Galnewa",
            "Gambirigaswewa",
            "Gemunupura",
            "Gonahaddenawa",
            "Habarana",
            "Halmillawetiya",
            "Halmillewa",
            "Hidogama",
            "Horowpothana",
            "Hurulunikawewa",
            "Ihalagama",
            "Ipalogama",
            "Kahatagasdigiliya",
            "Kahatagasdigliya",
            "Kalaoya",
            "Kalawedi Ulpotha",
            "Karagahawewa",
            "Kebithigollawa",
            "Kekirawa",
            "Kendewa",
            "Kirigalwewa",
            "Madatugama",
            "Mahabulankulama",
            "Maha Elagamuwa",
            "Mahailluppallama",
            "Mahawilachchiya",
            "Mailagaswewa",
            "Maneruwa",
            "Maradankadawala",
            "Medawachchiya",
            "Meegodawewa",
            "Megodawewa",
            "Mihintale",
            "Morakewa",
            "Nachchaduwa",
            "Nochchiyagama",
            "Nuwaragam Palatha",
            "Padavi Siripura",
            "Padavi Siritissapura",
            "Padaviya",
            "Parakumpura",
            "Parangiyawadiya",
            "Parasangahawewa",
            "Pemaduwa",
            "Pulmoddai",
            "Rajanganaya",
            "Rambewa",
            "Ranorawa",
            "Saliyapura",
            "Siyambalewa",
            "Talawa",
            "Tambuttegama",
            "Telhiriyawa",
            "Thalawa",
            "Thambuttegama",
            "Thanthirimale",
            "Thirappane",
            "Tittagonewa",
            "Udunuwara Colony",
            "Wahalkada",
            "Welimuwapotana",
            "Welioya Project"
          ],
        'Polonnaruwa':[
            "Aluthwewa",
            "Alutwewa",
            "Aralangawila",
            "Aselapura",
            "Attanakadawala",
            "Bakamuna",
            "Dalukana",
            "Damminna",
            "Dewagala",
            "Dimbulagala",
            "Divulankadawala",
            "Divuldamana",
            "Diyabeduma",
            "Diyasenpura",
            "Elahera",
            "Ellewewa",
            "Galamuna",
            "Galoya Junction",
            "Giritale",
            "Hansayapalama",
            "Hingurakdamana",
            "Hingurakgoda",
            "Jayanthipura",
            "Jayasiripura",
            "Kalingaela",
            "Kalukele Badanagala",
            "Kashyapapura",
            "Kawudulla",
            "Kawuduluwewa",
            "Kottapitiya",
            "Kumaragama",
            "Lakshauyana",
            "Maduruoya",
            "Maha Ambagaswewa",
            "Mahatalakolawewa",
            "Mahawela Sinhapura",
            "Mampitiya",
            "Manampitiya",
            "Medirigiriya",
            "Meegaswewa",
            "Minneriya",
            "Mutugala",
            "Nawasenapura",
            "Nelumwewa",
            "Nuwaragala",
            "Onegama",
            "Orubendi Siyambalawa",
            "Palugasdamana",
            "Parakrama Samudraya",
            "Pelatiyawa",
            "Pimburattewa",
            "Polonnaruwa Town",
            "Pulastigama",
            "Sevanapitiya",
            "Sinhagama",
            "Sungavila",
            "Talpotha",
            "Tamankaduwa",
            "Tambala",
            "Unagalavehera",
            "Welikanda",
            "Wijayabapura",
            "Yodaela",
            "Yudaganawa"
          ]
    },
    'Northern':{
        'Jaffna':[
            "Chavakachcheri",
            "Delft Island",
            "Jaffna Town",
            "Kankesanthurai",
            "Karainagar",
            "Kayts",
            "Kokuvil",
            "Mandaitivu",
            "Maviddapuram",
            "Nallur",
            "Point Pedro",
            "Puloly",
            "Vadamaradchy",
            "Valikamam East",
            "Valikamam North",
            "Valikamam South",
            "Valikamam West",
            "Valvettithurai"
          ],
        'Kilinochchi':[
            "Aliyavalai",
            "Kilinochchi",
            "Parantan",
            "Pooneryn"
          ],
        'Mannar':[
            "Mannar",
            "Pesalai",
            "Thalaimannar"
          ],
        'Mulativu':[
            "Mulativu Town"
          ],
        'Vavuniya':[
            "Omanthai",
            "Puliyankulam",
            "Vavuniya Town"
          ],
    },
    'North Western':{
        'Kurunegala':[
            "Alawwa",
            "Ambanpola",
            "Ataragalla",
            "Awulegama",
            "Balalla",
            "Bamunukotuwa",
            "Bandara Koswatta",
            "Bogahamulla",
            "Bopitiya",
            "Bujjomuwa",
            "Dambadeniya",
            "Deegalla",
            "Demataluwa",
            "Diddeniya",
            "Divullegoda",
            "Dodangaslanda",
            "Etungahakotuwa",
            "Galgamuwa",
            "Giriulla",
            "Gokaralla",
            "Halmillawewa",
            "Heraliyawela",
            "Hindagolla",
            "Hiruwalpola",
            "Horambawa",
            "Hulogedara",
            "Hulugalla",
            "Ibbagamuwa",
            "Ilukhena",
            "Indulgodakanda",
            "Inguruwatta",
            "Iriyagolla",
            "Ithanawatta",
            "Kadigawa",
            "Kahapathwala",
            "Kalugamuwa",
            "Kanadeniyawala",
            "Kanattewewa",
            "Katupota",
            "Kekunagolla",
            "Keppitiwalana",
            "Kirimetiyawa",
            "Kirindigalla",
            "Kithalawa",
            "Kobeigane",
            "Kohilagedara",
            "Konwewa",
            "Kosdeniya",
            "Kosgolla",
            "Kotawehera",
            "Kudagalagamuwa",
            "Kudakathnoruwa",
            "Kuliyapitiya",
            "Kumbukgeta",
            "Kumbukwewa",
            "Kuratihena",
            "Kurunegala Town",
            "Labbala",
            "Lbbagamuwa",
            "Lonahettiya",
            "Madahapola",
            "Madakumburumulla",
            "Maduragoda",
            "Maeliya",
            "Mahagalkadawala",
            "Mahagirilla",
            "Mahamukalanyaya",
            "Mahananneriya",
            "Maharachchimulla",
            "Maho",
            "Makulpotha",
            "Makulwewa",
            "Malagane",
            "Malkaduwawa",
            "Malpitiya",
            "Mandapola",
            "Maspotha",
            "Mawathagama",
            "Meegalawa",
            "Meewellawa",
            "Melsiripura",
            "Metikumbura",
            "Metiyagane",
            "Minhettiya",
            "Minuwangete",
            "Mirihanegama",
            "Moragane",
            "Moragollagama",
            "Munamaldeniya",
            "Nabadewa",
            "Nagollagama",
            "Nagollagoda",
            "Nakkawatta",
            "Narammala",
            "Narangoda",
            "Nawatalwatta",
            "Nelliya",
            "Nikadalupotha",
            "Nikaweratiya",
            "Padeniya",
            "Padiwela",
            "Pahalagiribawa",
            "Pahamune",
            "Palukadawala",
            "Panadaragama",
            "Panagamuwa",
            "Panaliya",
            "Panliyadda",
            "Pannala",
            "Pansiyagama",
            "Periyakadneluwa",
            "Pihimbiya Ratmale",
            "Pihimbuwa",
            "Pilessa",
            "Polgahawela",
            "Polpitigama",
            "Pothuhera",
            "Puswelitenna",
            "Ridigama",
            "Sandalankawa",
            "Sirisethagama",
            "Siyambalagamuwa",
            "Solewewa",
            "Sunandapura",
            "Talawattegedara",
            "Tambutta",
            "Thalahitimulla",
            "Thalakolawewa",
            "Thalwita",
            "Thambagalla",
            "Tharana Udawela",
            "Thimbiriyawa",
            "Thorayaya",
            "Tisogama",
            "Torayaya",
            "Tuttiripitigama",
            "Udubaddawa",
            "Uhumiya",
            "Usgala Siyabmalangamuwa",
            "Wadakada",
            "Wadumunnegedara",
            "Wannilhalagama",
            "Wannirasnayakapura",
            "Warawewa",
            "Wariyapola",
            "Watuwatta",
            "Welawa Junction",
            "Welipennagahamulla",
            "Wellagala",
            "Wellarawa",
            "Wellawa",
            "Wennoruwa",
            "Weuda",
            "Wewagama",
            "Yakwila"
          ],
        'Puttalam':[
            "Adippala",
            "Anamaduwa",
            "Andigama",
            "Angunawila",
            "Bangadeniya",
            "Baranankattuwa",
            "Battuluoya",
            "Bingiriya",
            "Bujjampola",
            "Chilaw",
            "Dankotuwa",
            "Dunkannawa",
            "Eluwankulama",
            "Ettale",
            "Ihala Kottaramulla",
            "Ihala Puliyankulama",
            "Kakkapalliya",
            "Kalpitiya",
            "Karativponparappi",
            "Karuwalagaswewa",
            "Katuneriya",
            "Kirimundalama",
            "Kudawewa",
            "Kumarakattuwa",
            "Kuruketiyawa",
            "Lihiriyagama",
            "Lunuwila",
            "Madampe",
            "Madurankuliya",
            "Mahauswewa",
            "Marawila",
            "Mundel",
            "Muttibendivila",
            "Nainamadama",
            "Nalladarankattuwa",
            "Nattandiya",
            "Nawagattegama",
            "Norachcholai",
            "Palaviya",
            "Pallama",
            "Palliwasalturai",
            "Pothuwatawana",
            "Puttalam Town",
            "Rajakadaluwa",
            "Saliyawewa Junction",
            "Tabbowa",
            "Talawila Church",
            "Toduwawa",
            "Udappu",
            "Udappuwa",
            "Uridyawa",
            "Uriyawa",
            "Vanathawilluwa",
            "Waikkal",
            "Watugahamulla",
            "Wennappuwa",
            "Wilpotha",
            "Yogiyana"
          ]
    },
    'Sabaragamuwa':{
        'Kegalle':[
            "Alawatura",
            "Algama",
            "Aluthnuwara",
            "Ambalakanda",
            "Ambulugala",
            "Amitirigala",
            "Ampagala",
            "Anhettigama",
            "Aranayake",
            "Aruggammana",
            "Atale",
            "Batuwita",
            "Beligala",
            "Berannawa",
            "Bopitiya",
            "Boralankada",
            "Bossella",
            "Bulathkohupitiya",
            "Damunupola",
            "Daraniyagala",
            "Debathgama",
            "Dedigama",
            "Dedugala",
            "Deewala Pallegama",
            "Dehiowita",
            "Deldeniya",
            "Deloluwa",
            "Deraniyagala",
            "Dewalegama",
            "Dewanagala",
            "Dombemada",
            "Dorawaka",
            "Dunumala",
            "Galapitamada",
            "Galatara",
            "Galigamuwa",
            "Galpatha",
            "Gantuna",
            "Gonagala",
            "Hakabellawaka",
            "Hakahinna",
            "Hakbellawaka",
            "Hawadiwela",
            "Helamada",
            "Hemmatagama",
            "Hettimulla",
            "Hewadiwela",
            "Hingula",
            "Hinguralakanda",
            "Hiriwadunna",
            "Imbulana",
            "Imbulgasdeniya",
            "Kabagamuwa",
            "Kannattota",
            "Kegalle Town",
            "Kehelpannala",
            "Kithulgala",
            "Kitulgala",
            "Kondeniya",
            "Kotiyakumbura",
            "Lewangama",
            "Mahapallegama",
            "Maharangalla",
            "Makehelwala",
            "Malalpola",
            "Maliboda",
            "Malmaduwa",
            "Mawanella",
            "Migastenna",
            "Miyanawita",
            "Molagoda",
            "Morontota",
            "Nelumdeniya",
            "Niyadurupola",
            "Noori",
            "Parape",
            "Pattampitiya",
            "Pitagaldeniya",
            "Rambukkana",
            "Ruwanwella",
            "Seaforth Colony",
            "Talgaspitiya",
            "Teligama",
            "Thatawella",
            "Tholangamuwa",
            "Thotawella",
            "Tulhiriya",
            "Tuntota",
            "Udagaldeniya",
            "Udapotha",
            "Udumulla",
            "Undugoda",
            "Ussapitiya",
            "Wahakula",
            "Waharaka",
            "Warakapola",
            "Watura",
            "Weeoya",
            "Wegalla",
            "Welihelatenna",
            "Weragala",
            "Yatagama",
            "Yatapana",
            "Yatiyantota",
            "Yattogoda"
          ],
        'Ratnapura':[
            "Akarella",
            "Atakalanapnna",
            "Ayagama",
            "Balangoda",
            "Batatota",
            "Belihuloya",
            "Bolthumbe",
            "Bulutota",
            "Dambuluwana",
            "Dela",
            "Delwala",
            "Demuwatha",
            "Dodampe",
            "Doloswalakanda",
            "Dumbara Manana",
            "Eheliyagoda",
            "Ekamuthugama",
            "Elapatha",
            "Ellagawa",
            "Ellawala",
            "Embilipitiya",
            "Erepola",
            "Gabbela",
            "Gallella",
            "Gangeyaya",
            "Gawaragiriya",
            "Getahetta",
            "Gillimale",
            "Godagampola",
            "Godakawela",
            "Gurubewilagama",
            "Halpe",
            "Halwinna",
            "Handagiriya",
            "Hapugastenna",
            "Hatangala",
            "Hatarabage",
            "Hiramadagama",
            "Ihalagalagama",
            "Imbulpe",
            "Ittakanda",
            "Kahangama",
            "Kahawatte",
            "Kalawana",
            "Kalthota",
            "Kaltota",
            "Karandana",
            "Karangoda",
            "Karawita",
            "Kella Junction",
            "Kiribbanwewa",
            "Kiriella",
            "Kolambageara",
            "Kolombugama",
            "Kolonna",
            "Kudawa",
            "Kuruwita",
            "Madalagama",
            "Madampe",
            "Mahagamakoloniya",
            "Mahawalatenna",
            "Makandura Sabara",
            "Matuwagalagama",
            "Meddekanda",
            "Minipura Dumbara",
            "Mitipola",
            "Morahela",
            "Mulendiyawala",
            "Nawalakanda",
            "Nivithigala",
            "Omalpe",
            "Opanayaka",
            "Padalangala",
            "Pallebedda",
            "Pambagolla",
            "Panamura",
            "Panawala",
            "Parakaduwa",
            "Pebotuwa",
            "Pelmadulla",
            "Pimbura",
            "Pinnawala",
            "Rajawaka",
            "Rakwana",
            "Ranwala",
            "Rassagala",
            "Ratna Hangamuwa",
            "Ratnapura Town",
            "Samanalawewa",
            "Sevanagala",
            "Sri Palabaddala",
            "Sudagala",
            "Teppanawa",
            "Tunkama",
            "Udakarawita",
            "Udaniriella",
            "Udawalawe",
            "Ullinduwawa",
            "Veddagala",
            "Vijeriya",
            "Waleboda",
            "Watapotha",
            "Waturawa",
            "Weligepola",
            "Welipathayaya",
            "Wewelwatta",
            "Wikiliya"
          ],
    },
    'Southern':{
        'Galle':[
            "Agaliya",
            "Ahangama",
            "Ahungalla",
            "Akmeemana",
            "Akuressa",
            "Aluthwala",
            "Ambalangoda",
            "Ampegama",
            "Amugoda",
            "Anangoda",
            "Angulugaha",
            "Ankokkawala",
            "Atakohota",
            "Avittawa",
            "Baddegama",
            "Balapitiya",
            "Banagala",
            "Batapola",
            "Benthota",
            "Boossa",
            "Dikkumbura",
            "Dodanduwa",
            "Elipitiya",
            "Ella Tanabaddegama",
            "Elpitiya",
            "Ethkandura",
            "Galle Town",
            "Gintota",
            "Godahena",
            "Gonagalpura",
            "Habaraduwa",
            "Haburugala",
            "Halvitigala Colony",
            "Hawpe",
            "Hikkaduwa",
            "Hiniduma",
            "Hiyare",
            "Ihalahewessa",
            "Ihala Walpola",
            "Imaduwa",
            "Induruwa",
            "Kahaduwa",
            "Kahawa",
            "Kananke Bazaar",
            "Karagoda",
            "Karandeniya",
            "Ketandola",
            "Koggala",
            "Kosgoda",
            "Kothalawala",
            "Kottawagama",
            "Kurundugahahethakma",
            "Madakumburamulla",
            "Magala North",
            "Magala South",
            "Magedara",
            "Malamura",
            "Malgalla Talangalla",
            "Mapalagama",
            "Mapalagama Central",
            "Mattaka",
            "Meda Keembiya",
            "Meetiyagoda",
            "Miriswatta",
            "Nagoda",
            "Nakiyadeniya",
            "Nawadagala",
            "Neluwa",
            "Nindana",
            "Opatha",
            "Panangala",
            "Pannimulla Panagoda",
            "Parana Thanayamgoda",
            "Pitigala",
            "Pitigala - North",
            "Porawagama",
            "Rantotuvila",
            "Talagampola",
            "Tawalama",
            "Thalgaswala",
            "Udalamatta",
            "Udugama",
            "Unawatuna",
            "Uragasmanhandiya",
            "Wackwella",
            "Walahanduwa",
            "Wanchawela",
            "Wanduramba",
            "Warukandeniya",
            "Weihena",
            "Yakkalamulla",
            "Yatalamatta"
          ],
        'Hambantota':[
            "Ambalantota",
            "Angunakolapalassa",
            "Beliatta",
            "Beragama",
            "Bundala",
            "Hambantota",
            "Hambantota Town",
            "Hungama",
            "Ittademaliya",
            "Julampitiya",
            "Kirinda",
            "Lunugamwehera",
            "Magama",
            "Middeniya",
            "Ranna",
            "Ridiyagama",
            "Sooriyawewa",
            "Tangalle",
            "Tissamaharama",
            "Walasmulla",
            "Weeraketiya",
            "Weerawila"
          ],
        'Matara':[
            "Akuressa",
            "Alapaladeniya",
            "Aparekka",
            "Aturaliya",
            "Bengamuwa",
            "Beralapanathara",
            "Bopagoda",
            "Dampahala",
            "Deegala Lenama",
            "Deiyandara",
            "Dellawa",
            "Denagama",
            "Denipitiya",
            "Deniyaya",
            "Derangala",
            "Devinuwara",
            "Devundara",
            "Dikwella",
            "Diyagaha",
            "Diyalape",
            "Gandara",
            "Godagama",
            "Godapitiya",
            "Gomila Mawarala",
            "Hakmana",
            "Handugala",
            "Hithetiya",
            "Horapawita",
            "Kalubowitiyana",
            "Kamburugamuwa",
            "Kamburupitiya",
            "Karagoda Uyangoda",
            "Karaputugala",
            "Karatota",
            "Kekanadura",
            "Kiriweldola",
            "Kiriwelkele",
            "Kolawenigma",
            "Kotapola",
            "Kottegoda",
            "Lankagama",
            "Makandura",
            "Maliduwa",
            "Malimboda",
            "Maramba",
            "Matara",
            "Matara Town",
            "Mediripitiya",
            "Miella",
            "Mirissa",
            "Modara",
            "Moragala Kirillapone",
            "Morawaka",
            "Mulatiyana Junction",
            "Nadugala",
            "Naimana",
            "Narawelpita",
            "Nawimana",
            "Nupe",
            "Pahala Millawa",
            "Palatuwa",
            "Pamburana",
            "Paragala",
            "Parapamulla",
            "Pasgoda",
            "Penetiyana",
            "Pitabeddara",
            "Pothdeniya",
            "Puhulwella",
            "Radawela",
            "Ransegoda",
            "Ratmale",
            "Rotumba",
            "Siyambalagoda",
            "Sultanagoda",
            "Talaramba",
            "Thelijjawila",
            "Thihagoda",
            "Thudawa",
            "Urubokka",
            "Urugamuwa",
            "Urumutta",
            "Uyanwatta",
            "Viharahena",
            "Walakanda",
            "Walasgala",
            "Walgama",
            "Wallasmulla",
            "Waralla",
            "Weligama",
            "Welihinda",
            "Wilpita",
            "Yatiyana"
          ]
    },
    'Uva':{
        'Badulla':[
            "Akkarasiyaya",
            "Aluketiyawa",
            "Aluththaramma",
            "Aluttaramma",
            "Ambadandegama",
            "Ambagahawatte",
            "Ambagasdowa",
            "Amunumulla",
            "Arawa",
            "Arawakumbura",
            "Arawatta",
            "Atakiriya",
            "Badulla Town",
            "Baduluoya",
            "Ballaketuwa",
            "Bambarapana",
            "Bandarawela",
            "Beramada",
            "Bibilegama",
            "Bogahakumbura",
            "Boragas",
            "Boralanda",
            "Bowela",
            "Dambana",
            "Demodara",
            "Diganatenna",
            "Dikkapitiya",
            "Dimbulana",
            "Divulapelessa",
            "Diyathalawa",
            "Dulgolla",
            "Egodawela",
            "Ella",
            "Ettempitiya",
            "Gadunna",
            "Galauda",
            "Galedanda",
            "Galporuyaya",
            "Gamewela",
            "Gawarawela",
            "Girandurukotte",
            "Godunna",
            "Guruthalawa",
            "Haldummulla",
            "Hali",
            "Hali-ela",
            "Hangunnawa",
            "Haputale",
            "Hawanakumbura",
            "Hebarawa",
            "Heeloya",
            "Helahalpe",
            "Helapupula",
            "Hewanakumbura",
            "Hingurukaduwa",
            "Hopton",
            "Idalgashinna",
            "Jangulla",
            "Kabillawela",
            "Kahataruppa",
            "Kalubululanda",
            "Kalugahakandura",
            "Kalupahana",
            "Kandaketiya",
            "Kandegedara",
            "Kandepuhulpola",
            "Kebillawela",
            "Kendagolla",
            "Keppetipola",
            "Keselpotha",
            "Ketawatta",
            "Kiriwanagama",
            "Koslanda",
            "Kotamuduna",
            "Kuruwitenna",
            "Kuttiyagolla",
            "Landewela",
            "Liyanagahawela",
            "Lunugala",
            "Lunuwatta",
            "Madulsima",
            "Mahiyangana",
            "Mahiyanganaya",
            "Makulella",
            "Malgoda",
            "Maliyadda",
            "Mapakadawewa",
            "Maspanna",
            "Maussagolla",
            "Medawelagama",
            "Medawela Udukinda",
            "Meegahakivula",
            "Metigahatenna",
            "Mirahawatta",
            "Miriyabedda",
            "Miyanakandura",
            "Namunukula",
            "Narangala",
            "Nelumgama",
            "Nikapotha",
            "Nugatalawa",
            "Ohiya",
            "Pahalarathkinda",
            "Pallekiruwa",
            "Passara",
            "Pathanewatta",
            "Pattiyagedara",
            "Pelagahatenna",
            "Perawella",
            "Pitamaruwa",
            "Pitapola",
            "Poonagala",
            "Puhulpola",
            "Ratkarawwa",
            "Rideemaliyadda",
            "Rilpola",
            "Silmiyapura",
            "Sirimalgoda",
            "Sorabora Colony",
            "Soragune",
            "Soranathota",
            "Spring Valley",
            "Taldena",
            "Tennepanguwa",
            "Timbirigaspitiya",
            "Uduhawara",
            "Uraniya",
            "Uva Deegalla",
            "Uva Karandagolla",
            "Uva Mawelagama",
            "Uvaparanagama",
            "Uva Tenna",
            "Uva Tissapura",
            "Uva Uduwara",
            "Welimada",
            "Wewatta",
            "Wineethagama",
            "Yalagamuwa",
            "Yalwela"
          ],
        'Monaragala':[
            "Angunakolawewa",
            "Ayiwela",
            "Badalkumbura",
            "Baduluwela",
            "Bakinigahawela",
            "Balaharuwa",
            "Bibile",
            "Buddama",
            "Buttala",
            "Dambagalla",
            "Diyakobala",
            "Dombagahawela",
            "Ekamutugama",
            "Ekiriyankumbura",
            "Ethimalewewa",
            "Ettiliwewa",
            "Galabedda",
            "Hambegamuwa",
            "Hulandawa",
            "Inginiyagala",
            "Kandaudapanguwa",
            "Kandawinna",
            "Kataragama",
            "Kiriibbanwewa",
            "Kotagama",
            "Kotawehera Mankada",
            "Kotiyagala",
            "Kudaoya",
            "Kumbukkana",
            "Mahagama Colony",
            "Marawa",
            "Mariarawa",
            "Medagana",
            "Monaragala",
            "Monaragala Town",
            "Moretuwegama",
            "Nakkala",
            "Nannapurawa",
            "Nelliyadda",
            "Nilgala",
            "Obbegoda",
            "Okkampitiya",
            "Pangura",
            "Pitakumbura",
            "Randeniya",
            "Ruwalwela",
            "Sella Kataragama",
            "Sewanagala",
            "Siyambalagane",
            "Siyambalanduwa",
            "Suriara",
            "Tanamalila",
            "Tanamalwila",
            "Uva Gangodagama",
            "Uva Kudaoya",
            "Uva Pelwatta",
            "Warunagama",
            "Wedikumbura",
            "Weherayaya Handapanagala",
            "Wellawaya",
            "Wilaoya"
          ],
    }
}
module.exports = [cities]


